import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { Shimmer } from '@pitchero/react-ui';
import CLUB_PROP_TYPES from 'lib/prop-types/club';

const HomepageNews = dynamic(() => import('./index'));

const LazyLoadedHomepageNews = ({ isLoading, news, isFreePackage, club }) => {
  const [isVisible, setVisible] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if ('IntersectionObserver' in window && ref.current) {
      const lazyNewsObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          const { isIntersecting } = entry;
          if (isIntersecting) {
            setVisible(true);
            observer.disconnect();
          }
        });
      });

      lazyNewsObserver.observe(ref.current);
    } else {
      // No IntersectionObserver, just show
      setVisible(true);
    }
  }, []);

  if (!isVisible) {
    return (
      <div ref={ref}>
        <Shimmer height={100} width="100%" />
      </div>
    );
  }

  return (
    <HomepageNews isLoading={isLoading} news={news} isFreePackage={isFreePackage} club={club} />
  );
};

LazyLoadedHomepageNews.propTypes = {
  isFreePackage: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  news: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  club: CLUB_PROP_TYPES.isRequired,
};

export default LazyLoadedHomepageNews;
