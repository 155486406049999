import React from 'react';
import PropTypes from 'prop-types';
import Advert from './advert';

/**
 * Native ad placement "Shelf"
 * Content controlled in AdButler via Google Tag Manager
 * This placement should collapse fully when not in use
 *
 * @returns {*}
 * @constructor
 */
const Shelf = ({ paddingProps }) => {
  // "ab" stands for "AdButler"
  return <Advert type="shelf" paddingProps={paddingProps} />;
};

Shelf.propTypes = {
  paddingProps: PropTypes.shape({}),
};
Shelf.defaultProps = {
  paddingProps: {},
};
export default Shelf;
