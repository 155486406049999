import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Grid } from '@pitchero/react-ui';
import ItemPreview from './item-preview';

const PreviewControls = ({ autoplay, news, onClickItem, selectedId }) => (
  <Cushion all="medium">
    <Grid className="grid-hp-fc-pc" columns="1fr 1fr 1fr 1fr" columnGap="small">
      {news.map((message) => (
        <ItemPreview
          key={message.id}
          autoplay={autoplay}
          onClick={() => onClickItem(message.id)}
          message={message}
          isSelected={message.id === selectedId}
        />
      ))}
    </Grid>
  </Cushion>
);

PreviewControls.propTypes = {
  autoplay: PropTypes.bool.isRequired,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onClickItem: PropTypes.func.isRequired,
  selectedId: PropTypes.string.isRequired,
};

export default PreviewControls;
