import React from 'react';
import PropTypes from 'prop-types';
import {
  Cushion,
  Hide,
  MaxWidth,
  PreloadImage,
  Rectangle,
  Space,
  Shadow,
} from '@pitchero/react-ui';
import { isEmpty, findIndex } from 'lodash';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import { imageSrc } from 'components/activity-stream/utils';
import HeaderBackgroundContainer from 'components/club-frame/header/background-container';
import Strip from 'components/native-adverts/strip';
import ActiveItem from './active-item';
import NoContent from './no-content';
import PreviewControls from './preview-controls';
import AUTOPLAY_DURATION_SECONDS from './constants';

class FeaturedContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoplay: false,
      selectedItemIndex: 1,
    };
  }

  componentDidMount() {
    const { news } = this.props;
    this.setState({
      autoplay: news.length > 1,
    });

    setInterval(this.autoplaying, AUTOPLAY_DURATION_SECONDS * 1000);
  }

  autoplaying = () => {
    const { autoplay } = this.state;
    if (autoplay) {
      this.nextItem();
    }
  };

  selectItemById = (itemId) => {
    const { news } = this.props;
    this.setState({
      selectedItemIndex: findIndex(news, { id: itemId }) + 1,
      autoplay: false,
    });
  };

  selectItemByIndex = (index) => {
    const { news } = this.props;
    if (index > news.length) {
      this.setState({ selectedItemIndex: 1 });
    } else if (index < 1) {
      this.setState({ selectedItemIndex: news.length });
    } else {
      this.setState({ selectedItemIndex: index });
    }
  };

  nextItem = () => {
    const { selectedItemIndex } = this.state;
    this.selectItemByIndex(selectedItemIndex + 1);
  };

  previousItem = () => {
    const { selectedItemIndex } = this.state;
    this.selectItemByIndex(selectedItemIndex - 1);
  };

  render() {
    const { club, news, isFreePackage } = this.props;
    const { autoplay, selectedItemIndex } = this.state;
    const selectedItem = news[selectedItemIndex - 1];

    if (isEmpty(news)) {
      return <NoContent club={club} />;
    }

    const wrapForFreePackage = (content) => {
      if (!isFreePackage) {
        return (
          <HeaderBackgroundContainer
            imgSrc={selectedItem ? imageSrc(selectedItem) : null}
            preset="card--large"
          >
            {content}
          </HeaderBackgroundContainer>
        );
      }

      return (
        <Space vertical="medium" responsive={[{ maxWidth: 'tab', props: { horizontal: 'small' } }]}>
          <Shadow>
            <Rectangle radius="rounded" fill="white">
              {content}
            </Rectangle>
          </Shadow>
        </Space>
      );
    };

    return wrapForFreePackage(
      <>
        <Strip
          fill={null}
          paddingProps={{
            top: 'medium',
            responsive: [{ maxWidth: 'tab', props: { horizontal: 'medium' } }],
          }}
        />
        <Cushion
          bottom="medium"
          component="div"
          className="homepage__featured-content"
          responsive={[{ maxWidth: 'tab', props: { bottom: '0' } }]}
        >
          <MaxWidth component="div" maxWidth={1366} center>
            <ActiveItem
              key={selectedItem.id}
              message={selectedItem}
              autoplay={autoplay}
              itemCount={news.length}
              itemIndex={selectedItemIndex}
              onNext={() => {
                this.setState({ autoplay: false });
                this.nextItem();
              }}
              onPrevious={() => {
                this.setState({ autoplay: false });
                this.previousItem();
              }}
            />

            {news.length > 1 && (
              <Hide maxWidth="tab" component="div">
                <PreviewControls
                  autoplay={autoplay}
                  news={news}
                  selectedId={selectedItem.id}
                  onClickItem={this.selectItemById}
                />
              </Hide>
            )}
            {news.map((message) => (
              <PreloadImage
                key={`preload-${message.id}`}
                preset="card--large"
                src={imageSrc(message)}
              />
            ))}
          </MaxWidth>
        </Cushion>
      </>,
    );
  }
}

FeaturedContent.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  isFreePackage: PropTypes.bool.isRequired,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
export default FeaturedContent;
