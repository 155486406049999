import PageDataLoader from 'lib/page-data/loader';
import PageKey from 'lib/page-data/key';

import {
  HOMEPAGE_REQUEST,
  HOMEPAGE_RECEIVE,
  HOMEPAGE_FAILURE,
  PAGE_DATA_LOAD,
} from 'store/action-types';

export const fetchHomepageData = (clubId, themeDraftId, request) => async (dispatch) => {
  try {
    dispatch({ type: HOMEPAGE_REQUEST, payload: { clubId, themeDraftId } });
    const pageKey = new PageKey(clubId, 'homepage', { themeDraftId });
    const pageData = await PageDataLoader.getPageData(pageKey, request);
    await dispatch({
      type: HOMEPAGE_RECEIVE,
      payload: { clubId, themeDraftId, pageData: pageData.data },
    });
    dispatch({ type: PAGE_DATA_LOAD, payload: pageKey.toString() });
    return {};
  } catch (error) {
    let statusCode = 500;
    if (error) {
      statusCode = error.errorStatusCode || statusCode;
    }

    dispatch({ type: HOMEPAGE_FAILURE, payload: { clubId, themeDraftId, statusCode, error } });
    return { error };
  }
};
