import React from 'react';
import PropTypes from 'prop-types';

const HeaderBackgroundHeightFinder = ({ containerRef, children, fillColor }) => {
  return (
    <div
      ref={containerRef}
      style={{ backgroundColor: fillColor }}
      id="js-headerBackgroundContainer"
    >
      {children}
    </div>
  );
};

HeaderBackgroundHeightFinder.propTypes = {
  containerRef: PropTypes.shape().isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  fillColor: PropTypes.string,
};

HeaderBackgroundHeightFinder.defaultProps = {
  fillColor: null,
};

export default HeaderBackgroundHeightFinder;
