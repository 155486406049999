import React, { useEffect, useState } from 'react';
import { Rectangle } from '@pitchero/react-ui';
import AUTOPLAY_DURATION_SECONDS from './constants';

const ProgressMeter = () => {
  const [progressWidth, setProgressWidth] = useState(0);
  useEffect(() => {
    setTimeout(() => setProgressWidth(100), 0);
    return () => setProgressWidth(0);
  }, []);

  return (
    <Rectangle
      radius="roundedBottomLeft"
      fill="featuredContentProgressBg"
      style={{
        height: 4,
        transform: `translateX(${-100 + progressWidth}%)`,
        transition: `transform ${AUTOPLAY_DURATION_SECONDS}s ease-out`,
      }}
    />
  );
};

export default ProgressMeter;
