import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import dynamic from 'next/dynamic';
import { Shimmer } from '@pitchero/react-ui';
import { FIXTURE_PROP_TYPE } from 'lib/prop-types/fixture';

const HomepageResults = dynamic(() => import('./index'));

const LazyLoadedHomepageResults = ({ results }) => {
  if (isEmpty(results)) {
    return null;
  }

  const [isVisible, setVisible] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if ('IntersectionObserver' in window && ref.current) {
      const lazyResultsObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          const { isIntersecting } = entry;
          if (isIntersecting) {
            setVisible(true);
            observer.disconnect();
          }
        });
      });

      lazyResultsObserver.observe(ref.current);
    } else {
      // No IntersectionObserver, just show
      setVisible(true);
    }
  }, []);

  if (!isVisible) {
    return (
      <div ref={ref}>
        <Shimmer height={100} width="100%" />
      </div>
    );
  }

  return <HomepageResults results={results} />;
};

LazyLoadedHomepageResults.propTypes = {
  results: PropTypes.arrayOf(FIXTURE_PROP_TYPE).isRequired,
};

export default LazyLoadedHomepageResults;
