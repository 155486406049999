import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import dynamic from 'next/dynamic';
import { Shimmer } from '@pitchero/react-ui';
import CLUB_PROP_TYPES from 'lib/prop-types/club';

const HomepageEvents = dynamic(() => import('./index'));

const LazyLoadedHomepageEvents = ({ club, clubEvents }) => {
  if (isEmpty(clubEvents)) {
    return null;
  }

  const [isVisible, setVisible] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if ('IntersectionObserver' in window && ref.current) {
      const lazyEventsObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          const { isIntersecting } = entry;
          if (isIntersecting) {
            setVisible(true);
            observer.disconnect();
          }
        });
      });

      lazyEventsObserver.observe(ref.current);
    } else {
      // No IntersectionObserver, just show
      setVisible(true);
    }
  }, []);

  if (!isVisible) {
    return (
      <div ref={ref}>
        <Shimmer height={100} width="100%" />
      </div>
    );
  }

  return <HomepageEvents club={club} clubEvents={clubEvents} />;
};

LazyLoadedHomepageEvents.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  clubEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default LazyLoadedHomepageEvents;
