import React from 'react';
import PropTypes from 'prop-types';
import { Space, Typography } from '@pitchero/react-ui';
import ArrowLeftIcon from 'icons/ArrowLeft';
import ArrowRightIcon from 'icons/ArrowRight';
import IconWithTheme from 'components/icon-with-theme';

const NumberedControls = ({ itemCount, itemIndex, onNext, onPrevious, style }) => (
  <Typography component="div" preset="button" color="featuredContentTypographyColor">
    <IconWithTheme>
      <ArrowLeftIcon
        preset="button"
        color="featuredContentTypographyColor"
        onClick={onPrevious}
        style={{ cursor: 'pointer' }}
      />
    </IconWithTheme>
    <Space
      horizontal="xsmall"
      component="span"
      style={{ display: 'inline-block', textAlign: 'center', width: '2.5em', ...style }}
    >
      {`${itemIndex} / ${itemCount}`}
    </Space>
    <IconWithTheme>
      <ArrowRightIcon
        preset="button"
        color="featuredContentTypographyColor"
        onClick={onNext}
        style={{ cursor: 'pointer' }}
      />
    </IconWithTheme>
  </Typography>
);

NumberedControls.defaultProps = {
  style: {},
};

NumberedControls.propTypes = {
  itemCount: PropTypes.number.isRequired,
  itemIndex: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  style: PropTypes.shape(),
};

export default NumberedControls;
