export const imageSrc = (message) => {
  switch (message.type) {
    case 'album':
      return message.cover_image;
    case 'video':
      return message.thumbnail;
    default:
      return message.image;
  }
};
export const title = (message) => {
  switch (message.type) {
    case 'match_preview':
      return message.fixture.opponent;
    default:
      return message.title;
  }
};

export const tagLine = (message) => {
  switch (message.type) {
    case 'match_report':
    case 'sponsored_content':
    case 'news':
      return message.tagline;
    case 'match_preview':
      return message.excerpt;
    default:
      return null;
  }
};

export const linkProps = (message, placement) => {
  switch (message.type) {
    case 'album':
      return {
        route: 'media.album',
        params: { id: message.album_id, _title: message.title },
      };
    case 'club_event':
      return {
        route: 'calendar.event',
        params: { id: message.event_id, _title: message.title },
      };
    case 'match_preview':
      return {
        route: 'team.match_centre.overview',
        params: { teamId: message.team_id, fixtureId: message.fixture.fixture_id },
      };
    case 'match_report':
      return {
        route: 'team.match_centre.report',
        params: { teamId: message.team_id, fixtureId: message.fixture.fixture_id },
      };
    case 'news':
      return {
        route: 'news.article',
        params: { id: message.article_id, _title: message.title },
      };
    case 'sponsored_content':
      return {
        route: 'news.sponsored',
        params: { id: message.content_id, _title: message.title, placement },
      };
    case 'team_selection':
      return {
        route: 'team.match_centre.lineup',
        params: { teamId: message.team_id, fixtureId: message.fixture.fixture_id },
      };
    case 'video':
      return {
        route: 'media.video',
        params: { id: message.video_id, _title: message.title },
      };
    default:
      return {};
  }
};
