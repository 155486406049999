import React from 'react';
import PropTypes from 'prop-types';
import HeaderContext from './context';

const HeaderBackgroundColor = ({ children }) => {
  return (
    <HeaderContext.Consumer>
      {({ backgroundColor, backgroundImage }) => {
        return (
          <div
            style={{
              backgroundColor:
                backgroundColor && !backgroundImage ? backgroundColor : 'transparent',
            }}
          >
            {children}
          </div>
        );
      }}
    </HeaderContext.Consumer>
  );
};

HeaderBackgroundColor.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default HeaderBackgroundColor;
