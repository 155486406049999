import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AbsoluteContainer,
  Button,
  Cushion,
  FlexContainer,
  Grid,
  Hide,
  Image,
  Rectangle,
  RelativeContainer,
  Shadow,
  Typography,
} from '@pitchero/react-ui';
import LockIcon from 'icons/Lock';
import { useSwipeable } from 'react-swipeable';
import ClubLink from 'components/club-link';
import IconWithTheme from 'components/icon-with-theme';
import { linkProps, imageSrc, title } from 'components/activity-stream/utils';
import PublishedDate from 'components/published-date';
import { Trans } from 'lib/i18n';
import isServer from 'lib/is-server';
import NumberedControls from './numbered-controls';
import ProgressMeter from './progress-meter';
import ActiveItemTitle from './title';

const ActiveItem = ({ autoplay, itemCount, itemIndex, message, onNext, onPrevious }) => {
  const [entered, setEntered] = useState(isServer());

  useEffect(() => {
    setTimeout(() => setEntered(true), 0);
    return () => setEntered(false);
  }, []);

  const entryStyle = {
    opacity: entered ? 1 : 0,
    transform: `translateX(${entered ? 0 : 50}px)`,
    transition: 'opacity .5s ease-in-out, transform 1.5s ease-out',
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: onNext,
    onSwipedRight: onPrevious,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const { isPrivate } = message;
  const imageStyle = { display: 'block' };
  if (isPrivate) {
    imageStyle.filter = 'blur(10px)';
    imageStyle.opacity = 0.9;
  }

  return (
    <Cushion
      top="xlarge"
      bottom="medium"
      horizontal="xxxlarge"
      component="div"
      responsive={[
        { maxWidth: 'palm', props: { horizontal: 'medium' } },
        { maxWidth: 'tab', props: { top: 'small' } },
      ]}
    >
      <div {...swipeHandlers}>
        <Grid
          alignItems="center"
          areas="'details image'"
          className="grid-hp-fc-ai"
          columns="50% 50%"
          rowGap="medium"
          responsive={[
            {
              maxWidth: 'tab',
              props: {
                columns: 'auto',
                areas: "'image' 'details'",
              },
            },
          ]}
        >
          <Cushion
            right="large"
            responsive={[{ maxWidth: 'tab', props: { right: '0' } }]}
            style={{ gridArea: 'details' }}
          >
            <FlexContainer justifyContent="space-between">
              <Rectangle fill="featuredContentTagBg" radius="rounded">
                <Cushion horizontal="xsmall" vertical={2}>
                  <Typography preset="name" color="featuredContentTag">
                    <Trans i18nKey={`common:${message.type}_activity_type`}>{message.type}</Trans>
                  </Typography>
                </Cushion>
              </Rectangle>
              {itemCount > 1 && (
                <NumberedControls
                  itemIndex={itemIndex}
                  itemCount={itemCount}
                  onNext={onNext}
                  onPrevious={onPrevious}
                  style={{ opacity: entered ? 1 : 0, transition: 'opacity 0.5s ease' }}
                />
              )}
            </FlexContainer>
            <div style={entryStyle}>
              <ActiveItemTitle message={message} />

              <Hide maxWidth="tab" component="div">
                <ClubLink {...linkProps(message, 'homepage-featured-content')}>
                  <a>
                    <Shadow shadow="dark">
                      <Button theme="featuredContentCta">
                        <Trans i18nKey={`common:${message.type}_activity_type_cta`}>
                          {message.type}
                        </Trans>
                      </Button>
                    </Shadow>
                  </a>
                </ClubLink>
              </Hide>
            </div>

            <Hide minWidth="tab">
              <Typography preset="description" color="featuredContentTypographyColor">
                <PublishedDate date={message.published} />
              </Typography>
            </Hide>
          </Cushion>
          <Shadow shadow="dark">
            <Rectangle radius="rounded">
              <RelativeContainer
                style={{ gridArea: 'image', overflow: 'hidden', height: 'auto', ...entryStyle }}
                component="div"
              >
                <ClubLink {...linkProps(message, 'homepage-featured-content')}>
                  <a>
                    <Image
                      src={imageSrc(message)}
                      alt={title(message)}
                      shimmerOnLoad
                      quality={isPrivate ? 5 : null}
                      preset="card--large"
                      fullWidth
                      style={imageStyle}
                    />
                    {isPrivate && (
                      <AbsoluteContainer
                        top="50%"
                        left="50%"
                        style={{
                          transform: 'translate(-50%, -50%)',
                          opacity: 0.9,
                        }}
                      >
                        <IconWithTheme>
                          <LockIcon color="white" size={72} />
                        </IconWithTheme>
                      </AbsoluteContainer>
                    )}
                  </a>
                </ClubLink>
                {autoplay && (
                  <Hide minWidth="tab" component="div">
                    <AbsoluteContainer bottom={0} left={0} right={0}>
                      <ProgressMeter key={message.id} />
                    </AbsoluteContainer>
                  </Hide>
                )}
              </RelativeContainer>
            </Rectangle>
          </Shadow>
        </Grid>
      </div>
    </Cushion>
  );
};

ActiveItem.propTypes = {
  autoplay: PropTypes.bool.isRequired,
  itemCount: PropTypes.number.isRequired,
  itemIndex: PropTypes.number.isRequired,
  message: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isPrivate: PropTypes.bool,
    published: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
};

export default ActiveItem;
