import React from 'react';
import PropTypes from 'prop-types';
import {
  AbsoluteContainer,
  Cushion,
  Rectangle,
  RelativeContainer,
  Space,
  Typography,
} from '@pitchero/react-ui';
import { title } from 'components/activity-stream/utils';
import PublishedDate from 'components/published-date';
import ClampLines from 'components/clamp-lines';
import { Trans } from 'lib/i18n';
import ProgressMeter from './progress-meter';

const ItemPreview = ({ autoplay, message, isSelected, onClick }) => {
  const fill = isSelected ? 'featuredContentItemPreviewBg' : 'transparent';

  return (
    <Rectangle className={`slide-${message.id}`} radius="rounded" fill={fill} onClick={onClick}>
      <Cushion
        horizontal="medium"
        vertical="small"
        style={{
          boxSizing: 'border-box',
          cursor: 'pointer',
          flex: 1,
          overflow: 'hidden',
          transition: 'background-color .25s ease-out',
        }}
      >
        <RelativeContainer>
          <Space right="small">
            <Rectangle fill="featuredContentTagBg" radius="rounded">
              <Cushion horizontal="xsmall" vertical={2}>
                <Typography preset="name" color="featuredContentTag">
                  <Trans i18nKey={`common:${message.type}_activity_type`}>{message.type}</Trans>
                </Typography>
              </Cushion>
            </Rectangle>
          </Space>

          {message.type !== 'club_event' && (
            <Typography preset="description" color="featuredContentTypographyColor" opacity={0.7}>
              <PublishedDate date={message.published} />
            </Typography>
          )}
          <Space vertical="small" component="div">
            <ClampLines
              color="featuredContentTypographyColor"
              component="div"
              preset="subtitle--small"
              numberOfLines={3}
            >
              {title(message)}
            </ClampLines>
          </Space>
          {isSelected && autoplay && (
            <AbsoluteContainer bottom={0} left={0} right={0}>
              <ProgressMeter />
            </AbsoluteContainer>
          )}
        </RelativeContainer>
      </Cushion>
    </Rectangle>
  );
};

ItemPreview.propTypes = {
  autoplay: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    published: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ItemPreview;
