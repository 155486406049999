import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SeoHead from 'components/seo';
import NoBackground from 'components/club-frame/header/no-background';
import HomepageTopBanner from 'components/adverts/homepage-top-banner';
import Skin from 'components/adverts/skin';
import { isEmpty } from 'lodash';
import { FIXTURE_PROP_TYPE } from 'lib/prop-types/fixture';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import { Cushion, Grid, MaxWidth, Rectangle, GridItem } from '@pitchero/react-ui';
import Strip from 'components/native-adverts/strip';
import Shelf from 'components/native-adverts/shelf';
import FeaturedContent from './featured-content';
import FeaturedContentLoadingPlaceholder from './featured-content/loading';
import Sponsors from './sponsors';
import ViewModeSwitch from './view-mode-switch';
import HomepageAlbums from './albums/lazy';
import HomepageEvents from './events/lazy';
import HomepageNews from './news/lazy';
import HomepageFixtures from './fixtures/lazy';
import HomepageResults from './results/lazy';
import HomepageTables from './tables/lazy';
import HomepageVideos from './videos/lazy';

const ClubHomepage = ({
  albums,
  club,
  clubEvents,
  fixtures,
  isLoading,
  metaDescription,
  news,
  results,
  tablesByTeam,
  videos,
}) => {
  const featuredNews = news.slice(0, 4);
  const otherNews = news.slice(4, 10);
  const [viewMode, setViewMode] = useState('news');
  const hasMatchesData = !(isEmpty(fixtures) && isEmpty(results) && isEmpty(tablesByTeam));
  const isFreePackage = club.advertisingTier === 1;

  const { sponsors = [] } = club;
  const homepageSponsors = sponsors.filter((sponsor) => sponsor.placements.includes('homepage'));
  return (
    <>
      <SeoHead club={club} description={metaDescription} route="homepage" title={club.siteTitle} />
      {viewMode === 'matches' && <NoBackground />}
      <Cushion
        horizontal={isFreePackage ? 'medium' : null}
        responsive={[{ maxWidth: 'tab', props: { horizontal: '0' } }]}
        className={`c-homepage--${viewMode}`}
        component="div"
      >
        {viewMode === 'matches' && (
          <>
            <Strip
              fill={!isFreePackage ? 'primary' : null}
              paddingProps={{
                top: 'medium',
                responsive: [{ maxWidth: 'tab', props: { horizontal: 'medium' } }],
              }}
            />
          </>
        )}
        {hasMatchesData && (
          <ViewModeSwitch
            setViewMode={setViewMode}
            viewMode={viewMode}
            isFreePackage={isFreePackage}
          />
        )}
        {viewMode === 'news' && (
          <div className="homepage__news">
            {isLoading ? (
              <FeaturedContentLoadingPlaceholder />
            ) : (
              <FeaturedContent club={club} news={featuredNews} isFreePackage={isFreePackage} />
            )}
            {homepageSponsors.length > 0 && (
              <Sponsors sponsors={homepageSponsors} isFreePackage={isFreePackage} />
            )}
            <Cushion
              top={club.sponsors.length > 0 ? '0px' : 'xlarge'}
              bottom={otherNews.length > 0 ? '0px' : 'xlarge'}
            >
              <Rectangle fill="homepageTopBannerBg">
                <HomepageTopBanner />
              </Rectangle>
            </Cushion>
            {(otherNews.length > 0 || isLoading) && (
              <HomepageNews
                isLoading={isLoading}
                news={otherNews}
                isFreePackage={isFreePackage}
                club={club}
              />
            )}
            <Shelf paddingProps={{ bottom: 'medium' }} />
          </div>
        )}
        {(hasMatchesData || !isEmpty(clubEvents)) && (
          <MaxWidth component="div" maxWidth={1366} center>
            <Rectangle fill="seashellgrey">
              <Cushion
                horizontal="medium"
                vertical="large"
                responsive={[{ maxWidth: 'tab', props: { horizontal: 'small' } }]}
                component="div"
                className={isEmpty(clubEvents) ? 'homepage__matches' : ''}
              >
                <Grid
                  className="grid-homepage-matches"
                  columns="1fr 1fr"
                  responsive={[{ maxWidth: 'tab', props: { columns: '1fr', columnGap: '0' } }]}
                  columnGap="medium"
                  rowGap="xlarge"
                >
                  {!isEmpty(results) && (
                    <GridItem
                      columnStart={1}
                      columnEnd={isEmpty(fixtures) ? 3 : 2}
                      responsive={[{ maxWidth: 'tab', props: { columnEnd: 1 } }]}
                      component="div"
                    >
                      <HomepageResults results={results} />
                    </GridItem>
                  )}
                  {!isEmpty(fixtures) && (
                    <GridItem
                      columnStart={isEmpty(results) ? 1 : 2}
                      columnEnd={3}
                      responsive={[{ maxWidth: 'tab', props: { columnEnd: 1 } }]}
                      component="div"
                    >
                      <HomepageFixtures fixtures={fixtures} />
                    </GridItem>
                  )}
                  {!isEmpty(tablesByTeam) && (
                    <GridItem
                      columnStart={1}
                      columnEnd={isEmpty(clubEvents) ? 3 : 2}
                      responsive={[{ maxWidth: 'tab', props: { columnEnd: 1 } }]}
                      component="div"
                    >
                      <HomepageTables tablesByTeam={tablesByTeam} />
                    </GridItem>
                  )}
                  {!isEmpty(clubEvents) && (
                    <GridItem
                      columnStart={isEmpty(tablesByTeam) ? 1 : 2}
                      columnEnd={3}
                      responsive={[{ maxWidth: 'tab', props: { columnEnd: 1 } }]}
                      component="div"
                    >
                      <HomepageEvents club={club} clubEvents={clubEvents} />
                    </GridItem>
                  )}
                </Grid>
              </Cushion>
            </Rectangle>
          </MaxWidth>
        )}
        <div className="homepage__news">
          <HomepageAlbums albums={albums} />
          <HomepageVideos videos={videos} />
        </div>
      </Cushion>
      <Skin />
    </>
  );
};

ClubHomepage.defaultProps = {
  albums: [],
  clubEvents: [],
  fixtures: [],
  news: [],
  results: [],
  tablesByTeam: [],
  videos: [],
};

ClubHomepage.propTypes = {
  albums: PropTypes.arrayOf(PropTypes.shape()),
  club: CLUB_PROP_TYPES.isRequired,
  clubEvents: PropTypes.arrayOf(PropTypes.shape()),
  fixtures: PropTypes.arrayOf(FIXTURE_PROP_TYPE),
  isLoading: PropTypes.bool.isRequired,
  metaDescription: PropTypes.string.isRequired,
  news: PropTypes.arrayOf(PropTypes.shape()),
  results: PropTypes.arrayOf(FIXTURE_PROP_TYPE),
  tablesByTeam: PropTypes.arrayOf(PropTypes.shape()),
  videos: PropTypes.arrayOf(PropTypes.shape()),
};

export default ClubHomepage;
