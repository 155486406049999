import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { PreloadImage, Rectangle } from '@pitchero/react-ui';
import HeaderContext from './context';
import HeaderBackgroundHeightFinder from './background-height';

const HeaderBackgroundContainer = ({ children, height, imgSrc, preset, skinnedBgColor }) => {
  const [mounted, setMounted] = useState(false);
  const containerRef = useRef();
  const [backgroundHeight, setBackgroundHeight] = useState(
    containerRef.current
      ? containerRef.current.offsetTop + (height || containerRef.current.offsetHeight)
      : 0,
  );

  const updateBackgroundHeight = () => {
    setBackgroundHeight(
      containerRef.current
        ? containerRef.current.offsetTop + (height || containerRef.current.offsetHeight)
        : 0,
    );
  };

  useEffect(() => {
    // Trigger a render on mount in order to get the ref for the container height
    setMounted(true);
  }, []);

  useEffect(() => {
    updateBackgroundHeight();
  }, [containerRef]);

  return (
    <HeaderContext.Consumer>
      {({ backgroundColor, showingSkins, updateHeight, updateImage }) => {
        if (showingSkins) {
          return skinnedBgColor ? (
            <Rectangle fill={skinnedBgColor}>{children}</Rectangle>
          ) : (
            children
          );
        }

        updateHeight(backgroundHeight);
        if (!preset) {
          updateImage(imgSrc);
        }

        return (
          <>
            <HeaderBackgroundHeightFinder
              containerRef={containerRef}
              fillColor={height || (imgSrc && mounted) ? null : backgroundColor}
              height={height}
            >
              {children}
            </HeaderBackgroundHeightFinder>
            {imgSrc && preset && (
              <PreloadImage
                preset={preset}
                key={imgSrc}
                src={imgSrc}
                onLoad={(event) => updateImage(event.target.currentSrc)}
              />
            )}
          </>
        );
      }}
    </HeaderContext.Consumer>
  );
};

HeaderBackgroundContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  height: PropTypes.number,
  imgSrc: PropTypes.string,
  preset: PropTypes.string,
  skinnedBgColor: PropTypes.string,
};

HeaderBackgroundContainer.defaultProps = {
  height: null,
  imgSrc: null,
  preset: null,
  skinnedBgColor: null,
};

export default HeaderBackgroundContainer;
