import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Cushion, Rectangle, Shadow, Space } from '@pitchero/react-ui';
import HomepageSponsor from './sponsor';

const scrollingAnimaition = (totalWidth) => keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-${totalWidth}px);
  }
`;

const AnimatedContainer = styled.div`
  animation: ${(props) => scrollingAnimaition(props.totalWidth)}
    ${(props) => props.animationDuration} linear infinite;
  height: 68px;
  position: absolute;
  top: 0;
  transform: translate3d(0, 0, 0);
`;
const StaticContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

// Total width of a sponsor inclusive of padding.
const SPONSOR_WIDTH = 220;

// The time the animation should take for a single sponsor to cross the screen at 1920*1080
const TIME_TO_ANIMATE_ACROSS_1080P = 60;

class HomepageSponsors extends React.Component {
  containerRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      animationStarted: false,
      width: 1024,
    };
  }

  componentDidMount() {
    const { clientWidth: width } = this.containerRef.current;
    this.setWidth(width);
  }

  componentDidUpdate(prevProps, prevState) {
    const { clientWidth: width } = this.containerRef.current;
    if (prevState.width !== width) {
      this.setWidth(width);
    }
  }

  animationDuration = () => {
    const { sponsors } = this.props;
    const { width } = this.state;
    const pagesOfSponsors = Math.ceil(sponsors.length / this.visibleSponsors());
    const timeToCrossScreen = (width / 1920) * TIME_TO_ANIMATE_ACROSS_1080P;
    return `${pagesOfSponsors * timeToCrossScreen}s`;
  };

  setWidth = (width) => {
    this.setState(() => ({
      animationStarted: true,
      width,
    }));
  };

  shouldAnimate = () => {
    const { sponsors } = this.props;
    const { animationStarted } = this.state;
    return animationStarted && this.visibleSponsors() < sponsors.length;
  };

  visibleSponsors = () => {
    const { width } = this.state;
    return width ? Math.ceil(width / SPONSOR_WIDTH) : 6;
  };

  wrapForFreePackage = (content) => {
    const { isFreePackage } = this.props;
    if (!isFreePackage) {
      return content;
    }

    return (
      <Space vertical="medium" responsive={[{ maxWidth: 'tab', props: { horizontal: 'small' } }]}>
        <Shadow>
          <Rectangle radius="rounded" fill="white">
            {content}
          </Rectangle>
        </Shadow>
      </Space>
    );
  };

  render() {
    const { sponsors } = this.props;
    const totalWidth = SPONSOR_WIDTH * sponsors.length;
    const visibleSponsors = this.visibleSponsors();
    const Container = this.shouldAnimate() ? AnimatedContainer : StaticContainer;

    const sectionOneStyle = {
      left: 0,
      width: totalWidth,
    };

    // Section two repeats the first page of sponsors and adds them at the end,
    // this creates the illusion of an infinite loop.
    const sectionTwoStyle = {
      left: totalWidth,
      width: SPONSOR_WIDTH * visibleSponsors,
    };

    return this.wrapForFreePackage(
      <Rectangle fill="white">
        <Cushion top="xlarge" bottom="xlarge" style={{ textAlign: 'center' }} component="div">
          <div
            ref={this.containerRef}
            style={{
              height: 68,
              position: 'relative',
              overflow: 'hidden',
              transform: 'translate3d(0, 0, 0)',
              maxWidth: '100vw',
            }}
          >
            <Container
              animationDuration={this.animationDuration()}
              style={sectionOneStyle}
              totalWidth={totalWidth}
            >
              {sponsors.map((sponsor) => (
                <HomepageSponsor key={sponsor.id} {...sponsor} />
              ))}
            </Container>

            {this.shouldAnimate() && (
              <Container
                animationDuration={this.animationDuration()}
                style={sectionTwoStyle}
                totalWidth={totalWidth}
              >
                {sponsors.slice(0, visibleSponsors).map((sponsor) => (
                  <HomepageSponsor key={`${sponsor.id}-2`} {...sponsor} />
                ))}
              </Container>
            )}
          </div>
        </Cushion>
      </Rectangle>,
    );
  }
}

HomepageSponsors.propTypes = {
  isFreePackage: PropTypes.bool.isRequired,
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
};

export default HomepageSponsors;
