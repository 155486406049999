import React from 'react';
import {
  Cushion,
  FlexContainer,
  Grid,
  Hide,
  MaxWidth,
  Shadow,
  Shimmer,
  ShimmerImage,
  ShimmerTypography,
  Space,
} from '@pitchero/react-ui';

const FeaturedContentLoadingPlaceholder = () => {
  return (
    <MaxWidth component="div" maxWidth={1366} center>
      <Cushion
        vertical="medium"
        horizontal="xxxlarge"
        responsive={[{ maxWidth: 'palm', props: { horizontal: 'medium' } }]}
      >
        <Grid
          areas="'details image'"
          columns="50% 50%"
          responsive={[
            {
              maxWidth: 'tab',
              props: {
                columns: 'auto',
                areas: "'image' 'details'",
              },
            },
          ]}
        >
          <Cushion
            right="large"
            responsive={[{ maxWidth: 'tab', props: { right: '0' } }]}
            style={{ gridArea: 'details' }}
          >
            <FlexContainer justifyContent="space-between">
              <ShimmerTypography preset="tab--active" width="15%" />
              <ShimmerTypography preset="tab--active" width="15%" />
            </FlexContainer>
            <Space vertical="large">
              <ShimmerTypography preset="title--large" />
              <ShimmerTypography preset="title--large" />
              <ShimmerTypography preset="title--large" width="20%" />
            </Space>
          </Cushion>
          <Space bottom="medium" style={{ gridArea: 'image' }} component="div">
            <Shadow shadow="dark">
              <ShimmerImage preset="card--large" fullWidth />
            </Shadow>
          </Space>
        </Grid>
      </Cushion>
      <Hide maxWidth="tab" component="div">
        <Cushion all="medium">
          <Grid columns="1fr 1fr 1fr 1fr" columnGap="small">
            <Shimmer height={100} radius="rounded" />
            <Shimmer height={100} radius="rounded" />
            <Shimmer height={100} radius="rounded" />
            <Shimmer height={100} radius="rounded" />
          </Grid>
        </Cushion>
      </Hide>
    </MaxWidth>
  );
};

export default FeaturedContentLoadingPlaceholder;
