import React from 'react';
import PropTypes from 'prop-types';
import { get, isFinite } from 'lodash';
import { Typography, withTheme } from '@pitchero/react-ui';
import styled from 'styled-components';

function convertToNumber(value) {
  return value.replace('px', '') * 1;
}

const ClampLines = ({ numberOfLines, ...otherProps }) => <Typography {...otherProps} />;

ClampLines.propTypes = {
  numberOfLines: PropTypes.number.isRequired,
  preset: PropTypes.string.isRequired,
  uiTheme: PropTypes.shape({}).isRequired,
};

const StyledClampLines = styled(ClampLines)`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => {
    const preset = get(props.uiTheme, `typography.presets.${props.preset}`, null);
    if (!preset) {
      return 'max-height: auto';
    }

    const { lineHeight = 'auto', responsive = [] } = preset;
    if (!isFinite(convertToNumber(lineHeight))) {
      return 'max-height: auto';
    }

    return responsive.reduce((css, breakpoint) => {
      const { minWidth, props: { lineHeight: breakpointLineHeight } = {} } = breakpoint;
      const breakpointSize = get(props.uiTheme, `breakpoints.${minWidth}`, null);
      if (
        !breakpointSize ||
        !breakpointLineHeight ||
        !isFinite(convertToNumber(breakpointLineHeight))
      ) {
        return css;
      }

      return `
          ${css}
          @media screen and (min-width: ${breakpointSize}px) {
            max-height: ${convertToNumber(breakpointLineHeight) * props.numberOfLines}px;
          }
        `;
    }, `max-height: ${convertToNumber(lineHeight) * props.numberOfLines}px;`);
  }};
`;

export default withTheme(StyledClampLines);
