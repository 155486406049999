import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Shimmer } from '@pitchero/react-ui';
import dynamic from 'next/dynamic';

const HomepageAlbums = dynamic(() => import('./index'));

const LazyLoadedHomepageAlbums = ({ albums }) => {
  if (isEmpty(albums)) {
    return null;
  }

  const [isVisible, setVisible] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if ('IntersectionObserver' in window && ref.current) {
      const lazyAlbumsObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          const { isIntersecting } = entry;
          if (isIntersecting) {
            setVisible(true);
            observer.disconnect();
          }
        });
      });

      lazyAlbumsObserver.observe(ref.current);
    } else {
      // No IntersectionObserver, just show
      setVisible(true);
    }
  }, []);

  if (!isVisible) {
    return (
      <div ref={ref}>
        <Shimmer height={100} width="100%" />
      </div>
    );
  }

  return <HomepageAlbums albums={albums} />;
};

LazyLoadedHomepageAlbums.propTypes = {
  albums: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default LazyLoadedHomepageAlbums;
