import React from 'react';
import { Cushion, MaxWidth, Space, Typography } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';
import CLUB_PROP_TYPES from 'lib/prop-types/club';

const NoContent = ({ club }) => (
  <MaxWidth component="div" maxWidth={600} center>
    <Cushion
      vertical="xlarge"
      horizontal="xxxlarge"
      responsive={[{ maxWidth: 'palm', props: { horizontal: 'medium' } }]}
    >
      <Typography preset="tab--active" color="primary" align="center" component="div">
        <Trans i18nKey="common:homepage_welcome_message">Welcome to</Trans>
      </Typography>
      <Space top="small">
        <Typography
          preset="title--large"
          color="black"
          component="h1"
          align="center"
          style={{ hyphens: 'none' }}
        >
          {club.name}
        </Typography>
      </Space>
    </Cushion>
  </MaxWidth>
);

NoContent.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
};

export default NoContent;
