import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import fromNow from 'fromnow';
import { DateTime } from 'luxon';

const PublishedDate = ({ date }) => {
  if (!date) {
    return null;
  }

  const publishedLuxon = DateTime.fromISO(date);
  const now = DateTime.local();
  const daysAgo = now.diff(publishedLuxon, 'days');

  if (daysAgo.days < 7) {
    return fromNow(publishedLuxon.toISO(), { max: 1, suffix: true });
  }

  if (publishedLuxon.year === now.year) {
    return publishedLuxon.toFormat('d MMMM');
  }

  return publishedLuxon.toFormat('d MMMM yyyy');
};

PublishedDate.defaultProps = {
  date: null,
};

PublishedDate.propTypes = {
  date: PropTypes.string,
};

export default PublishedDate;
