import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Container, Space, withTheme, Cushion } from '@pitchero/react-ui';
import ClubLink from 'components/club-link';
import { linkProps, title, tagLine } from 'components/activity-stream/utils';
import ClampLines from 'components/clamp-lines';

const titleNumberOfLines = 3;
const titlePresetName = 'title--large';
const taglineNumberOfLines = 2;
const taglinePresetName = 'subtitle--large';

const ActiveItemTitle = ({ message, uiTheme }) => {
  // Manually set the height of the title + tagline container, to stop any jumping that may occur.
  // (titleLineHeight + titleNumberOfLines) + (taglineLineHeight + taglineNumberOfLines) + paddingTopAroundTagline.
  // same for all responsive rules
  const containerProps = {
    height: 29 * titleNumberOfLines + 18 * taglineNumberOfLines + get(uiTheme, 'spacing.small'),
    responsive: [
      {
        minWidth: 'palm',
        props: {
          height:
            29 * titleNumberOfLines + 24 * taglineNumberOfLines + get(uiTheme, 'spacing.small'),
        },
      },
      {
        minWidth: 'tab',
        props: {
          height:
            36 * titleNumberOfLines + 24 * taglineNumberOfLines + get(uiTheme, 'spacing.small'),
        },
      },
      {
        minWidth: 'lap',
        props: {
          height:
            36 * titleNumberOfLines + 24 * taglineNumberOfLines + get(uiTheme, 'spacing.medium'),
        },
      },
    ],
  };

  return (
    <Space
      vertical="medium"
      responsive={[{ minWidth: 'lap', props: { vertical: 'large' } }]}
      component="div"
    >
      <Container {...containerProps}>
        <>
          <ClampLines
            preset={titlePresetName}
            numberOfLines={titleNumberOfLines}
            component="div"
            color="featuredContentTypographyColor"
            className="active-item__title"
          >
            <ClubLink {...linkProps(message)}>
              <a style={{ hyphens: 'manual' }}>{title(message)}</a>
            </ClubLink>
          </ClampLines>
          <Cushion
            top="small"
            component="div"
            responsive={[{ minWidth: 'lap', props: { top: 'medium' } }]}
          >
            <ClampLines
              color="featuredContentTypographyColor"
              preset={taglinePresetName}
              numberOfLines={taglineNumberOfLines}
            >
              {tagLine(message)}
            </ClampLines>
          </Cushion>
        </>
      </Container>
    </Space>
  );
};

ActiveItemTitle.propTypes = {
  message: PropTypes.shape().isRequired,
  uiTheme: PropTypes.shape().isRequired,
};

export default withTheme(ActiveItemTitle);
