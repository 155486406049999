import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Show, Switch, Typography } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';
import HeaderBackgroundColor from 'components/club-frame/header/background-color';

const ViewModeSwitch = ({ isFreePackage, setViewMode, viewMode }) => {
  const content = (
    <Cushion top="medium" bottom="small" horizontal="medium">
      <Show maxWidth="tab" component="div" style={{ textAlign: 'center' }}>
        <Switch
          preset="primaryDark"
          checked={viewMode === 'matches'}
          onChange={() => setViewMode(viewMode === 'matches' ? 'news' : 'matches')}
          renderLeft={() => (
            <Cushion horizontal="medium" vertical="small">
              <Typography preset="button" component="div">
                <Trans i18nKey="common:homepage_view_mode_news">News</Trans>
              </Typography>
            </Cushion>
          )}
          renderRight={() => (
            <Cushion horizontal="medium" vertical="small">
              <Typography preset="button" component="div">
                <Trans i18nKey="common:homepage_view_mode_matches">Matches</Trans>
              </Typography>
            </Cushion>
          )}
        />
      </Show>
    </Cushion>
  );

  if (isFreePackage) {
    return content;
  }

  return <HeaderBackgroundColor>{content}</HeaderBackgroundColor>;
};

ViewModeSwitch.propTypes = {
  isFreePackage: PropTypes.bool.isRequired,
  setViewMode: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired,
};

export default ViewModeSwitch;
